import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This cheatsheet contains keybindings that are specific for LunarVim and NeoVim. All the `}<a parentName="p" {...{
        "href": "%22./vim.mdx%22"
      }}>{`vim keybindings`}</a>{` apply.`}</p>
    <p>{`By defalt the `}<inlineCode parentName="p">{`<leader>`}</inlineCode>{` key is space, but you can remap it to something else if you wish. The `}<inlineCode parentName="p">{`<M>`}</inlineCode>{` key is `}<inlineCode parentName="p">{`alt`}</inlineCode>{`/`}<inlineCode parentName="p">{`option`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "plugins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#plugins",
        "aria-label": "plugins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Plugins`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE`}</inlineCode>{` - toggles `}<inlineCode parentName="li">{`Which Key`}</inlineCode>{` plugin which displays a popup with possible key bindings of the command you started typing.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE+e`}</inlineCode>{` - toggle file explorer on/off`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE+f`}</inlineCode>{` - toggle telescope on/off which allows you to find files`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE+;`}</inlineCode>{` - show lunarvim dashboard (the first page when you run `}<inlineCode parentName="li">{`lvim`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CONTROL+\\`}</inlineCode>{` - toggle terminal on/off`}</li>
    </ul>
    <h2 {...{
      "id": "windows-and-tabs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows-and-tabs",
        "aria-label": "windows and tabs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows and Tabs`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CONTROL+h`}</inlineCode>{` - Move cursor to the left window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CONTROL+j`}</inlineCode>{` - Move cursor to the lower window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CONTROL+k`}</inlineCode>{` - Move cursor to the upper window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CONTROL+l`}</inlineCode>{` - Move cursor to the right window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE bb`}</inlineCode>{` - Show previous tabbed window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SPACE bn`}</inlineCode>{` - Show next tabbed window`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      